/**
 * Gets the original marked default options.
 */
export function _getDefaults() {
    return {
        async: false,
        baseUrl: null,
        breaks: false,
        extensions: null,
        gfm: true,
        headerIds: false,
        headerPrefix: '',
        highlight: null,
        hooks: null,
        langPrefix: 'language-',
        mangle: false,
        pedantic: false,
        renderer: null,
        sanitize: false,
        sanitizer: null,
        silent: false,
        smartypants: false,
        tokenizer: null,
        walkTokens: null,
        xhtml: false
    };
}
export let _defaults = _getDefaults();
export function changeDefaults(newDefaults) {
    _defaults = newDefaults;
}
